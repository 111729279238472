import { first, uniq } from 'lodash';
import { IReconnectState } from './../interfaces/index';
import { RequestClientContext } from '../interfaces';
import {
  INS_NUDGE_ID,
  INS_PRODUCT_CURRENCY,
  INS_SESSION_ID,
  IS_IN_MEETING,
  MEETING_RECONNECT_STATE,
  MEETING_ROLE
} from './constants';
import { isUserOnMobile } from './deviceDetector';
import { getIdentityId } from './identity';
import { inIframe } from './iframeDetector';
import { StoreType } from 'polotno/model/store';
import { format } from 'date-fns';
import { PageConfigContext } from '../advisorHub/clientSideServices/pageConfig';
import { TLanguage } from '../mappers/polotno';

export const POLOTNOSTORE = 'POLOTNOSTORE';

export const getScrollArea = () =>
  first(document.getElementsByClassName('content-wrapper'));

export const getVerticalScrollRange = () => {
  const scrollArea = getScrollArea();
  return scrollArea ? scrollArea.scrollHeight - scrollArea.clientHeight : 0;
};

export const getScrolledVerticalOffset = () => {
  return getScrollArea()?.scrollTop || 0;
};

export const performScroll = (arg: ScrollToOptions) => {
  getScrollArea()?.scroll(arg);
};

export const performSmoothScroll = (sectionId) => {
  //TO-DO smooth scroll on safari
  const distanceToTop = (el) => Math.floor(el.getBoundingClientRect().top);
  const targetID = sectionId;
  const targetAnchor = document.querySelector(targetID);
  if (!targetAnchor) return;
  const originalTop = distanceToTop(targetAnchor);
  getScrollArea()?.scroll({ top: originalTop, left: 0, behavior: 'smooth' });
};

export const goToUrl = (url: string) => {
  window.location.assign(url);
};

export const jumpToAnchor = (id: string) => {
  location.hash = id;
  setTimeout(() => {
    location.hash = '';
  }, 100);
};

export const scrollToBottom = () => {
  try {
    window.scrollTo(0, document.body.scrollHeight);
  } catch (e) {
    console.log(e);
  }
};

export const scrollToTop = () => {
  try {
    window.scrollTo(0, 0);
  } catch (e) {
    console.log(e);
  }
};

export const getCurrentHost = () =>
  typeof window !== 'undefined'
    ? `${window.location.protocol}//${window.location.host}`
    : '';

export const getElementDimension = (elem) => {
  if (elem) {
    const box = elem?.getBoundingClientRect();
    return {
      width: Math.round(box?.width) || 0,
      height: Math.round(box?.height) || 0
    };
  }
};

export const getElementPosition = (elem) => {
  const box = elem.getBoundingClientRect();
  const bodyRect = document.body.getBoundingClientRect();

  const top = Math.round(box.top),
    left = Math.round(box.left),
    bottom = bodyRect.height - Math.round(box.bottom),
    right = bodyRect.width - Math.round(box.right);
  return {
    top,
    left,
    bottom,
    right,
    middleFromTop: top + box.height / 2,
    middleFromLeft: left + box.width / 2,
    middleFromBottom: bottom + box.height / 2,
    middleFromRight: right + box.width / 2,
    topEdgeFromBottom: bottom + box.height,
    bottomEdgeFromTop: top + box.height
  };
};

export const isOrientationPortrait = (): boolean =>
  window.innerHeight > window.innerWidth;

export const isParentWindowInMeeting = () =>
  !!tryGetParentWindowField(IS_IN_MEETING);
export const getParentWindowMeetingRole = () =>
  tryGetParentWindowField(MEETING_ROLE);

export const scrollElementIntoViewById = (id: string, jump?: boolean) =>
  document.getElementById(id)?.scrollIntoView({
    behavior: jump ? undefined : 'smooth',
    block: 'nearest',
    inline: 'center'
  });

export const scrollElementToCenterContainer = (
  element,
  container,
  jump?: boolean
) => {
  const elementLeft = element.offsetLeft;
  const elementWidth = element.offsetWidth;
  const containerWidth = container.offsetWidth;
  const center = containerWidth / 2;
  const distanceFromCenter = elementLeft + elementWidth / 2 - center;
  container.scroll({
    left: distanceFromCenter,
    behavior: jump ? undefined : 'smooth'
  });
};

export const performNativeShare = (
  payload: ShareData,
  onSuccess: () => void,
  onFailure: () => void
) => {
  if (navigator.share && isUserOnMobile()) {
    navigator.share(payload).catch((error) => {
      if (error.toString().includes('AbortError')) {
        console.log('Aborted sharing');
      }
    });
    onSuccess();
  } else {
    onFailure();
  }
};

export const performNativeShareAsync = async (
  payload: ShareData,
  onSuccess: () => void,
  onFailure: () => void
) => {
  if (navigator?.['canShare'] && isUserOnMobile()) {
    try {
      await navigator.share(payload);
      onSuccess();
    } catch (err) {
      if (JSON.stringify(err) === '{}') onSuccess();
      else onFailure();
    }
  } else {
    onFailure();
  }
};

export const tryGetParentWindowField = (field: string) => {
  if (!isOnClientSide()) return;

  let result;
  try {
    result = window.parent[field];
  } catch (e) {
    return;
  }
  return result;
};

export const getViewportSize = () => {
  return {
    vw: Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    ),
    vh: Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    )
  };
};

export const tryGetLocalStorage = (key: string) => {
  let result;
  try {
    result = localStorage.getItem(key);
  } catch (e) {
    console.error('failed to get from local storage');
  }
  return result;
};

export const trySetLocalStorage = (key: string, value: string) => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    console.error('failed to set local storage');
  }
};

export const tryRemoveFromLocalStorage = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.error('failed to remove from local storage');
  }
};

export const getUrlWithParentWindowQueryParams = () => {
  if (!inIframe()) {
    return location.href;
  }
  let parentSearchQueryParams = '';
  /* eslint-disable no-empty */
  try {
    parentSearchQueryParams = window.parent.location?.search;
  } catch (e) {}
  /* eslint-enable no-empty */
  if (!parentSearchQueryParams) {
    return location.href;
  }
  const currentUrlContainsSearchQueryParams = /\?.+=.*/.test(location.href);
  if (currentUrlContainsSearchQueryParams) {
    return `${location.href}${parentSearchQueryParams.replace('?', '&')}`;
  }
  return `${location.href}${parentSearchQueryParams}`;
};

export const isOnClientSide = () => typeof window !== 'undefined';

export interface SspControls {
  getDeviceId: () => string;
  getCredentials: () => string;
  startSspSession: (
    hostName: string,
    serverUrl: string,
    meetingId: string
  ) => void;
  joinMeeting: (
    hostName: string,
    serverUrl: string,
    meetingId: string,
    webviewUrl: string,
    autoAdmit: boolean
  ) => void;
  sendVoyageMessage: (message: string) => void;
  exit: () => void;
  provideCredentials?: () => boolean;
  getStreamingTokens?: () => string;
  closeDocumentViewer?: () => void;
  //to send events to ssp
  dispatchEvent?: (event: string, data?: any) => void;
}

export const getSspControls = (): SspControls | undefined => {
  if (isOnClientSide()) {
    return window['SspControls'] as SspControls;
  }
  return;
};

const getStoreId = (): string | undefined => {
  if (!isOnClientSide()) return;
  if (window['store']) {
    return window['store'].id;
  }
  if (tryGetParentWindowField('store')) {
    return tryGetParentWindowField('store').id;
  }
  return getWindowField('storeId');
};

const getWindowField = (field): string | undefined => {
  if (!isOnClientSide()) return;
  if (window[field]) {
    return window[field] as any;
  }
  if (tryGetParentWindowField(field)) {
    return tryGetParentWindowField(field);
  }
};

export const getClientContext = (): RequestClientContext => {
  const storeId = getStoreId();
  const nudgeId = getWindowField(INS_NUDGE_ID);
  const sessionId = getWindowField(INS_SESSION_ID);
  const userId = getIdentityId();
  return {
    NUDGE: nudgeId,
    SESSION: sessionId,
    STORE: storeId,
    USER: userId
  };
};

export const getNudgeCurrencyCountryCode = () =>
  getWindowField(INS_PRODUCT_CURRENCY);

export const getPolotnoStore = () =>
  getWindowField('polotnoStore') as unknown as StoreType;

export const setPolotnoContext = (context: string) => {
  if (isOnClientSide()) {
    window['polotnoContext'] = context;
  }
};

export const setPolotnoType = (context: PageConfigContext) => {
  if (isOnClientSide()) {
    window['polotnoType'] = context;
  }
};

export const getPolotnoContext = () =>
  getWindowField('polotnoContext') as unknown as string;

export const getPolotnoType = () =>
  getWindowField('polotnoType') as unknown as string;

export const getClientContextRequestHeader = (
  additional: object = undefined
) => ({
  headers: {
    'x-client-context': btoa(
      JSON.stringify({
        ...getClientContext(),
        ...(additional || {})
      })
    )
  }
});
interface NativeNotificationPayload {
  title: string;
  body: string;
  icon?: any;
}

export const performNativeNotify = (payload: NativeNotificationPayload) => {
  if (Notification && Notification.permission === 'granted') {
    try {
      const notification = new Notification(payload.title, {
        body: payload?.body,
        icon: payload?.icon,
        badge: payload.icon,
        silent: false
      });
      return notification;
    } catch (error) {
      return;
    }
  }
  return;
};

export const getKnownIframeHosts = () => {
  // TODO: Update new hub url by brand once we know the domain
  return uniq([
    `${location.protocol}//${location.host}`,
    `http://localhost:3000`,
    `https://hub-dev.inspify.com/`
  ]);
};

export const getReconnectState = (meetingId: string): IReconnectState => {
  try {
    const reconnectState = localStorage.getItem(MEETING_RECONNECT_STATE);
    if (reconnectState) {
      const _preState = JSON.parse(reconnectState);
      const { id, ts } = _preState;
      const expiredTime = 5 * 60 * 1000;
      const isExpired = Date.now() - ts >= expiredTime;
      if (id !== meetingId || isExpired) {
        localStorage.removeItem(MEETING_RECONNECT_STATE);
        return undefined;
      }
      return _preState;
    }
  } catch (error) {
    return undefined;
  }
};

export const fadeAudio = (
  audioElement: HTMLAudioElement,
  from: number,
  to: number,
  duration = 2000
) => {
  if (!audioElement) return Promise.reject();
  const isFadeOut = from > to;
  const FADE_ROUND = 10;
  const intervalTime = duration / FADE_ROUND;
  const volumeChangePerRound = Math.abs(from - to) / FADE_ROUND;
  const adjustVolume = (isFadeOut ? -1 : 1) * volumeChangePerRound;
  const promise: Promise<void> = new Promise((resolve, reject) => {
    try {
      audioElement.volume = from;

      const intervalId = setInterval(() => {
        const shouldStop = isFadeOut
          ? audioElement.volume <= to
          : audioElement.volume >= to;
        const newVolume = +(audioElement.volume + adjustVolume).toPrecision(3);
        const correctVolume = isFadeOut
          ? Math.max(to, newVolume)
          : Math.min(to, newVolume);

        if (shouldStop) {
          audioElement.volume = to;
          clearInterval(intervalId);
          resolve();
        }
        audioElement.volume = correctVolume;
      }, intervalTime);
    } catch (error) {
      reject(error);
    }
  });
  return promise;
};

export const isOnboardingBrand = () => {
  try {
    const value = localStorage.getItem('onboarding');
    return value === 'true';
  } catch (error) {
    return false;
  }
};

export const setIsOnboardingBrand = (payload: boolean) => {
  try {
    return localStorage.setItem('onboarding', String(payload));
  } catch (e) {
    console.error(e);
  }
};

export const CrossOriginLocalStorage = function (
  currentWindow,
  iframe,
  onMessage,
  onError
) {
  let childWindow;
  // some browser (don't remember which one) throw exception when you try to access
  // contentWindow for the first time, it works when you do that second time
  try {
    childWindow = iframe.contentWindow;
  } catch (e) {
    childWindow = iframe.contentWindow;
  }

  currentWindow.onmessage = (event) => {
    try {
      // console.log(event.data);
      return onMessage(JSON.parse(event.data), event);
    } catch (error) {
      onError?.();
      // uncaught error
    }
  };

  this.getData = () => {
    const messageData = {
      method: 'get'
    };
    this.postMessage(messageData);
  };

  this.setData = (data) => {
    const messageData = {
      method: 'set',
      data: data
    };
    this.postMessage(messageData);
  };

  this.postMessage = (messageData) => {
    childWindow.postMessage(JSON.stringify(messageData), '*');
  };
};

export const dateFormat = (date: string, formatType: string) =>
  new Date(date).toString() !== 'Invalid Date'
    ? format(new Date(date), formatType)
    : 'unknown';

export const requestFullScreen = ({
  onSuccess,
  onError
}: {
  onSuccess?: () => void;
  onError?: (error: any) => void;
}) => {
  const meetingWrapper = document.querySelector('body.in-meeting');
  const nextWrapper = document.querySelector('#__next');
  const element = meetingWrapper || nextWrapper;
  if (element) {
    // @ts-ignore
    const requestMethod =
      element.requestFullscreen ||
      // @ts-ignore
      element.webkitRequestFullScreen ||
      // @ts-ignore
      element.mozRequestFullScreen ||
      // @ts-ignore
      element.msRequestFullscreen;
    if (requestMethod) {
      try {
        requestMethod.call(element);
        onSuccess && onSuccess();
      } catch (e) {
        onError && onError(e);
      }
    } else if (onError) {
      onError('Fullscreen API is not supported.');
    }
  } else if (onError) {
    onError('Element not found.');
  }
};

export const exitFullScreen = ({
  onSuccess,
  onError
}: {
  onSuccess?: () => void;
  onError?: (error: any) => void;
}) => {
  const requestMethod =
    document.exitFullscreen ||
    // @ts-ignore
    document.webkitExitFullscreen ||
    // @ts-ignore
    document.mozCancelFullScreen ||
    // @ts-ignore
    document.msExitFullscreen;
  if (requestMethod && document) {
    try {
      requestMethod.call(document);
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      if (onError) {
        onError(e);
      }
    }
  } else if (onError) {
    onError('Fullscreen API is not supported.');
  }
};

export const isFullScreen = () => {
  return (
    document.fullscreenElement ||
    // @ts-ignore
    document.webkitFullscreenElement ||
    // @ts-ignore
    document.mozFullScreenElement ||
    // @ts-ignore
    document.msFullscreenElement
  );
};

export const popupwindow = (url, title, w, h) => {
  const left = screen.width / 2 - w / 2;
  const top = screen.height / 2 - h / 2;
  return window.open(
    url,
    title,
    'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
      w +
      ', height=' +
      h +
      ', top=' +
      top +
      ', left=' +
      left
  );
};

export const scrollPolotnoPageToCenter = (pages: HTMLCollectionOf<Element>) => {
  Array.from(pages).forEach((element) => {
    setTimeout(() => {
      element.scrollTo({
        left: (element.scrollWidth - element.clientWidth) / 2,
        behavior: 'smooth'
      });
    }, 100);
  });
};

export const copyToClipboard = (copyText: string) => {
  navigator.clipboard.writeText(copyText);
};

export const supportedLanguages = ['en', 'ja', 'kr', 'hk', 'cn', 'de', 'fr'];

const browserLanguageToSBLanguage = {
  en: 'en',
  ja: 'ja',
  jv: 'ja',
  ko: 'kr',
  'zh-hk': 'hk',
  zh: 'cn',
  de: 'de',
  fr: 'fr'
};

export const mapBrowerLanguageToSBLanguage = (
  browerLanguage: string
): TLanguage => {
  let language = browserLanguageToSBLanguage[browerLanguage];

  if (!language) {
    const baseLanguage = browerLanguage.split('-')[0];
    language = browserLanguageToSBLanguage[baseLanguage];
  }

  return language || ('en' as TLanguage);
};

export const extractPageId = (input: string) => {
  let id = '';
  let language: TLanguage = 'en';
  let isPortrait = false;
  if (input.includes('-portrait')) {
    isPortrait = true;
  }
  const splitted = input.split('-portrait');
  const splitted2 = splitted[0];
  const l = splitted2.slice(-2);
  if (supportedLanguages.includes(l)) {
    language = l as TLanguage;
    id = splitted2.substring(0, splitted2.length - 3);
  } else {
    id = splitted2;
    language = 'en';
  }

  return {
    id,
    language,
    isPortrait
  };
};
