import { IPolotnoJSON } from '../mappers/polotno';
import { ILibraryScene } from '../interfaces';
import { getLibrarySceneById } from '../advisorHub/clientSideServices/library';
import { isEqual, uniqWith } from 'lodash';
import { uploadBase64ToS3 } from '../advisorHub/clientSideServices/aws';
import { v4 as uuid } from 'uuid';
import { StoreType } from 'polotno/model/store';

type PolotnoLayer = {
  json: IPolotnoJSON;
  exportOptions?: object;
  uploadOptions?: {
    bucket: string;
    Key: string;
    ContentType: string;
  };
};

export const generatedImageFromServer = async (layers: PolotnoLayer[]) => {
  const renderingStore = window['polotnoRenderingStore'] as StoreType;
  const urls = await Promise.all(
    layers.map(async (layer) => {
      if (!layer) {
        return null;
      }
      const { json, exportOptions, uploadOptions } = layer;
      if (json.pages.length === 0) {
        console.log({ json });
        return null;
      }
      const pageJson = json.pages[0];
      const page = renderingStore.addPage({
        ...pageJson,
        id: uuid()
      });
      const dataUrl = (await renderingStore.toDataURL({
        pageId: page.id,
        ...exportOptions
      })) as string;
      renderingStore.deletePages([page.id]);
      if (layer.uploadOptions) {
        return uploadBase64ToS3({
          Bucket: uploadOptions.bucket,
          Key: uploadOptions.Key,
          ContentType: uploadOptions.ContentType,
          Body: dataUrl
        });
      }
      return dataUrl;
    })
  );

  return urls as string[];
};

export const scenePageHasChanged = async (sceneId: string, pages) => {
  const originalScene: ILibraryScene = await getLibrarySceneById(sceneId);
  const originalPages = JSON.parse(originalScene.content.overlay)?.pages || [];
  let scenePageHasChanged = false;
  if (!pages || !pages.length) scenePageHasChanged = true;
  pages.map((page) => {
    const originalPage = originalPages.find((p) => p.id === page.id);
    if (
      !originalPage ||
      !isEqual(
        JSON.parse(JSON.stringify(originalPage)),
        JSON.parse(JSON.stringify(page))
      )
    ) {
      scenePageHasChanged = true;
      return scenePageHasChanged;
    }
  });

  return scenePageHasChanged;
};

export const removeUnusedFontsInScene = (scene: ILibraryScene) => {
  try {
    if (!scene.content.overlay || !scene.content.settings) return scene;
    const overlay = JSON.parse(scene.content.overlay);
    const customFonts = JSON.parse(scene.content.settings)?.customFont;
    const usedFonts = [];

    overlay.pages.forEach((page) => {
      page.children.forEach((child) => {
        if (child.fontFamily) {
          usedFonts.push(child.fontFamily);
        }
      });
    });

    const filteredFontsList = customFonts.filter((font) =>
      usedFonts.includes(font.fontFamily)
    );

    return {
      ...scene,
      content: {
        ...scene.content,
        settings: JSON.stringify({
          ...JSON.parse(scene.content.settings),
          customFont: uniqWith(filteredFontsList, isEqual)
        })
      }
    };
  } catch (error) {
    return scene;
  }
};

export const removeUnusedFontsInPage = (page: IPolotnoJSON) => {
  try {
    if (!page.fonts || !page.pages) return page;
    const usedFonts = [];

    page.pages.forEach((page) => {
      page.children.forEach((child) => {
        if (child.fontFamily) {
          usedFonts.push(child.fontFamily);
        }
      });
    });

    const filteredFontsList = page.fonts.filter((font) =>
      usedFonts.includes(font.fontFamily)
    );

    return {
      ...page,
      fonts: uniqWith(filteredFontsList, isEqual)
    };
  } catch (error) {
    return page;
  }
};
