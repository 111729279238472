import { chunk, flatten } from 'lodash';
import {
  checkAndFixPolotnoJson,
  checkAndFixPolotnoJsonString,
  checkForMissingPolotnoPage,
  getPolotnoJson,
  getSceneLayer,
  getSceneLayerForPreview,
  renderLayers,
  renderMultiplePartLayers,
  TLanguage
} from './../../../mappers/polotno';
import { extractPageId, getPolotnoStore } from './../../../utils/window';
import {
  ITagsVisibilityPayload,
  LibrarySceneType,
  IConvertedFiles,
  SceneLayerSettings,
  ISceneLayer,
  IStorybook
} from './../../../interfaces/index';
import { ILibraryScene, ILibraryScenePayload } from '../../../interfaces';
import { mapStorybookFilesToScenes } from '../../../mappers/library';
import { prodBaseUrlV2 } from '../../config';
import {
  apigClient,
  getObjectFromS3,
  listObjectsFromS3,
  uploadBase64ToS3,
  uploadToS3
} from '../aws';
import { IPolotnoJSON } from '../../../mappers/polotno';
import { assetBaseUrl } from '../../../config';
import { StoreType } from 'polotno/model/store';
import {
  getAvailableLanguageInScene,
  getPolotnoJsonInScene,
  getSceneContentType
} from '../../utils/library';
import { mapStorybookFilesResponseToStorybook } from '../../../mappers/storybook';
import {
  generatedImageFromServer,
  removeUnusedFontsInPage
} from '../../../utils/polotno';
import { generateV4UUID } from '../../../utils/identityGenerator';
import { blobToDataURL, reduceSizeAndWidth } from '../../utils/image';
import pLimit from 'p-limit'
export const editorJsonBucket = 'inspify-library';
export const editorImageBucket = 'inspify-assets';

export const servicesVersion = 'v2';

export interface ILibrarySearchParams {
  userId: string;
  keywords?: string;
  startIndex?: number;
  teamIds?: string[];
  activatedOnly?: boolean;
  context?: LibrarySceneType;
  excludeMine?: boolean;
}

export const getLibrarySceneFile = async <T extends boolean>({
  sceneId,
  asStorybook
}: {
  sceneId: string;
  asStorybook?: T;
}): Promise<T extends true ? IStorybook[] : ILibraryScene[]> => {
  const client = await apigClient(prodBaseUrlV2);
  const path = `/libraries/v1/scenes/uploadedFiles`;
  const body = {
    sceneId
  };
  try {
    const result = await client.invokeApi({}, path, 'POST', {}, body);
    if (asStorybook) {
      return result.data.map(mapStorybookFilesResponseToStorybook);
    } else {
      return result.data.map(mapStorybookFilesToScenes).flat();
    }
  } catch (e) {
    console.log('error get file list', e);
    return [];
  }
};

export const getLibrarySceneById = async (
  id: string,
  version?: number
): Promise<ILibraryScene> => {
  const client = await apigClient(prodBaseUrlV2);
  const additionalParams = version
    ? {
        queryParams: {
          version
        }
      }
    : {};

  const path = `/libraries/${servicesVersion}/scenes/${id}`;
  const { data } = await client.invokeApi({}, path, 'GET', additionalParams);
  const editorVersion = version || data.version;

  const type = getSceneContentType(data);

  if (data.content.overlay || type !== 'layer')
    return {
      ...data,
      content: {
        ...data.content,
        overlay: checkAndFixPolotnoJsonString(data.content.overlay)
      }
    };

  try {
    try {
      const editor = await getEditorJsonAsync({
        id: data.id,
        version: editorVersion
      });
      return {
        ...data,
        content: { ...data.content, overlay: JSON.stringify(editor) }
      };
    } catch (e) {
      const editor = await getEditorJsonAsync({
        id: data.id,
        version: editorVersion - 1
      });
      return {
        ...data,
        content: { ...data.content, overlay: JSON.stringify(editor) }
      };
    }
  } catch (e) {
    return data;
  }
};

export const getActivatedSceneById = async (
  id: string
): Promise<ILibraryScene> => {
  const client = await apigClient(prodBaseUrlV2);
  const path = `/libraries/${servicesVersion}/scenes/${id}/activated`;
  const { data } = await client.invokeApi({}, path, 'GET', {});
  return data;
};

export const saveLibraryScene = async (payload: ILibraryScenePayload) => {
  const client = await apigClient(prodBaseUrlV2);
  const path = `/libraries/${servicesVersion}/scenes`;
  const result = await client.invokeApi({}, path, 'POST', {}, payload);
  return result;
};

export const deleteLibraryScene = async (id: string) => {
  const client = await apigClient(prodBaseUrlV2);
  const path = `/libraries/${servicesVersion}/scenes/${id}`;
  const result = await client.invokeApi({}, path, 'DELETE', {});
  return result;
};

export const activateScene = async (id: string, activatedBy: string) => {
  const client = await apigClient(prodBaseUrlV2);
  const path = `/libraries/${servicesVersion}/scenes/activate`;
  const result = await client.invokeApi(
    {},
    path,
    'POST',
    {},
    { id, activatedBy }
  );
  return result;
};

export const searchScene = async ({
  userId,
  keywords,
  startIndex,
  activatedOnly,
  teamIds,
  excludeMine,
  context
}: ILibrarySearchParams): Promise<ILibraryScene[]> => {
  const client = await apigClient(prodBaseUrlV2);

  const contextQuery =
    context === 'template' ? `type: 'template'` : `(not type: 'template')`;

  const hasTeam = teamIds?.length > 0;
  const teamsQuery = hasTeam
    ? ` (and visibility: 'team' (or ${teamIds
        .map((id) => `visibility_scope: '${id}'`)
        .join(' ')}))`
    : ` (and visibility: 'team' visibility_scope: 'none')`;

  const filterInvisible = `(not visibility: 'hide')`;
  const queryString = excludeMine
    ? `(and (not createdby: '${userId}')${teamsQuery})`
    : hasTeam
    ? `(or createdby: '${userId}'${teamsQuery})`
    : `createdby: '${userId}'`;

  const body = {
    keywords: keywords || '',
    size: 10,
    startIndex: startIndex || 0,
    queryString: `(and ${contextQuery} ${filterInvisible} ${queryString})`
  };

  const additionalParams = activatedOnly
    ? {
        queryParams: {
          activated: true
        }
      }
    : {};

  const path = `/libraries/${servicesVersion}/scenes/search`;
  const result = await client.invokeApi(
    {},
    path,
    'POST',
    additionalParams,
    body
  );
  return result.data.scenes;
};

export const updateSceneAttributes = async (body: ITagsVisibilityPayload) => {
  const client = await apigClient(prodBaseUrlV2);
  const path = `/libraries/${servicesVersion}/scenes/attributes`;
  return await client.invokeApi({}, path, 'POST', {}, body);
};

export const getEditorJson = ({
  id,
  version,
  onSuccess,
  onError
}: {
  id: string;
  version: number;
  onSuccess: (json: IPolotnoJSON) => void;
  onError?: () => void;
}) => {
  getObjectFromS3(editorJsonBucket, `editor/${id}/v${version}.json`)
    .then((data) => {
      onSuccess(JSON.parse(data.Body.toString()));
    })
    .catch(() => {
      onError?.();
    });
};

export const getEditorJsonAsync = async ({
  id,
  version
}: {
  id: string;
  version: number;
}): Promise<IPolotnoJSON> => {
  const data = await getObjectFromS3(
    editorJsonBucket,
    `editor/${id}/v${version}.json`
  );
  return checkAndFixPolotnoJson(JSON.parse(data.Body.toString()));
};

export const saveEditorJson = ({
  id,
  version,
  json,
  onSuccess,
  onError
}: {
  id: string;
  version: number;
  json: IPolotnoJSON;
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  uploadToS3({
    Body: Buffer.from(JSON.stringify(json)),
    Bucket: editorJsonBucket,
    Key: `editor/${id}/v${version || '1'}.json`,
    ContentType: 'application/json'
  })
    .then(() => onSuccess?.())
    .catch(() => onError?.());
};

type SceneGeneratedPage = {
  name: string;
  pixelRatio?: number;
  pageId?: string;
  isPortrait?: boolean;
  language?: TLanguage;
};
export class RenderLayerQueue {
  size = 0;
  queueLayers = [];
  renderedResponse: any;
  constructor(size: number) {
    this.size = size;
  }

  async add(input, onComplete) {
    const layerIndex = this.queueLayers.length;

    this.queueLayers.push(input);

    if (this.queueLayers.length === this.size) {
      this.renderedResponse = await renderMultiplePartLayers(this.queueLayers);
    }
    let timer = setInterval(() => {
      if (this.renderedResponse?.mapped?.[layerIndex]) {
        onComplete(this.renderedResponse.mapped[layerIndex]);
        clearInterval(timer);
        timer = null;
      }
    });
    setTimeout(() => {
      if (timer) {
        console.log('Render timeout for layer ');
        clearInterval(timer);
      }
    }, 60000);
  }
}
const uploadRenderedLayers = async (
  layers: any[],
  store: StoreType,
  keyBase: string,
  renderQueue?: RenderLayerQueue
): Promise<SceneLayerSettings[]> => {
  try {
    let mapped;
    if (renderQueue) {
      mapped = await new Promise((resolve, reject) => {
        renderQueue.add({ layers, store, keyBase }, (rs) => {
          if (rs) {
            resolve(rs);
          } else {
            reject();
          }
        });
      });
    } else {
      mapped = (await renderLayers(layers, store, keyBase)).mapped;
    }

    const fileExtension = (transparent: boolean) =>
      transparent ? 'png' : 'jpeg';
    const fileName = (i: number, transparent: boolean) =>
      `${keyBase}layer_${i + 1}.${fileExtension(transparent)}`;
    return mapped.map((layer, index) => ({
      ...layer,
      url:
        layer.type === 'video' || layer.type === 'gif'
          ? layer.url
          : `${assetBaseUrl}/${fileName(index, layer.transparent)}`
    }));
  } catch (e) {
    return undefined;
  }
};

export const savePageLayers = async (
  store: StoreType,
  keyBase: string,
  scene?: ILibraryScene,
  isNewScene?: boolean,
  upload = true
): Promise<ISceneLayer> => {
  let layers = {
    desktop: undefined,
    mobile: undefined
  };

  if (isNewScene) {
    layers = getSceneLayerForPreview(getPolotnoJson().obj, scene) || {
      desktop: undefined,
      mobile: undefined
    };
  } else {
    layers = (await getSceneLayer(getPolotnoJson().obj, scene)) || {
      desktop: undefined,
      mobile: undefined
    };
  }

  if (!layers.desktop) return;
  try {
    const renderedLayers: ISceneLayer = {};
    const renderQueue = new RenderLayerQueue(
      Object.keys(layers).filter((key) => {
        return upload && layers[key].needGenerateLayer && layers[key].value;
      }).length
    );

    const results = await Promise.all(
      Object.keys(layers).map((key) => {
        const rendered =
          upload && layers[key].needGenerateLayer && layers[key].value
            ? uploadRenderedLayers(
                layers[key].value,
                store,
                `${keyBase}/${key}_`,
                renderQueue
              )
            : layers[key].value;
        return rendered;
      })
    );

    results.map((result, index) => {
      renderedLayers[Object.keys(layers)[index]] = result;
    });

    return renderedLayers;
  } catch (e) {
    return e;
  }
};

export interface PolotnoGeneratedImages {
  mainImage?: string;
  thumbnail?: string;
  preview?: string;
  portraitImage?: string;
  portraitThumbnail?: string;
  portraitPreview?: string;
  layer?: ISceneLayer;
}

export const mapLanguageToImageName = (name: string, language: TLanguage) => {
  return `${name}${language === 'en' ? '' : `-${language}`}`;
};

export const saveSceneImage = async ({
  key,
  initStore,
  scene,
  isNewScene
}: {
  key: string;
  initStore?: StoreType;
  scene?: ILibraryScene;
  isNewScene?: boolean;
}): Promise<PolotnoGeneratedImages> => {
  const store = initStore || getPolotnoStore();
  const generatedKey = generateV4UUID();
  const scenePages = getPolotnoJsonInScene(scene)?.pages;

  const keyBase = `content/generated/${key}/${generatedKey}`;

  const languages = getAvailableLanguageInScene(scene);

  const landscapeImage: SceneGeneratedPage[] = [];
  const sceneImages: SceneGeneratedPage[] = [];
  const portraitImage: SceneGeneratedPage[] = [];

  languages.map((l) => {
    sceneImages.push(
      {
        name: `${keyBase}/${mapLanguageToImageName('scene', l)}.jpeg`,
        language: l
      },
      {
        name: `${keyBase}/${mapLanguageToImageName('scene_portrait', l)}.jpeg`,
        language: l,
        isPortrait: true
      }
    );

    landscapeImage.push(
      {
        name: `${keyBase}/${mapLanguageToImageName('scene_preview', l)}.jpeg`,
        pixelRatio: 0.5,
        language: l
      },
      {
        name: `${keyBase}/${mapLanguageToImageName('scene_thumbnail', l)}.jpeg`,
        pixelRatio: 0.3,
        language: l
      }
    );

    portraitImage.push(
      {
        name: `${keyBase}/${mapLanguageToImageName(
          'scene_preview_portrait',
          l
        )}.jpeg`,
        pixelRatio: 0.5,
        language: l
      },
      {
        name: `${keyBase}/${mapLanguageToImageName(
          'scene_thumbnail_portrait',
          l
        )}.jpeg`,
        pixelRatio: 0.3,
        language: l
      }
    );
  });

  const allSmallImages = [
    ...landscapeImage,
    ...portraitImage.map((p) => ({ ...p, isPortrait: true }))
  ];

  try {
    const sceneLayers = sceneImages.map((image) => {
      const languagePages = scenePages.filter((p) =>
        p.id.includes(`${image.language === 'en' ? '' : `-${image.language}`}`)
      );
      const page = languagePages.find((p) =>
        image.isPortrait
          ? p.id.includes('-portrait')
          : !p.id.includes('-portrait')
      );

      if (!page) return null;
      return {
        json: removeUnusedFontsInPage({
          ...store.toJSON(),
          pages: [page]
        }),
        exportOptions: {
          pixelRatio: image.pixelRatio,
          mimeType: 'image/jpeg'
        },
        uploadOptions: {
          bucket: editorImageBucket,
          Key: image.name,
          ContentType: 'image/jpeg'
        },
        language: image.language,
        isPortrait: image.isPortrait
      };
    });

    const layerChunks = chunk(sceneLayers, 2);
    const limit = pLimit(2)
    await Promise.all(
      layerChunks.map((chunk) => limit(()=>generatedImageFromServer(chunk)))
    );
    const uploadSmallImagesPromise = Promise.all(
      allSmallImages.map(async (image) => {
        return new Promise((resolve, reject) => {
          const index = sceneImages.findIndex(
            (sceneImage) =>
              sceneImage.language === image.language &&
              sceneImage.isPortrait === image.isPortrait
          );
          const img = document.createElement('img');
          img.setAttribute('crossorigin', 'anonymous');
          const imageUrl = `${assetBaseUrl}/${sceneImages[index].name}`;
          img.src = imageUrl;
          img.onload = async () => {
            try {
              const reducedImage = await reduceSizeAndWidth(
                img,
                image.name,
                'image/jpeg',
                image.pixelRatio,
                1000
              );

              // Convert the Blob to a data URL
              const dataURL = await blobToDataURL(reducedImage);
              uploadBase64ToS3({
                Body: dataURL as string,
                Bucket: editorImageBucket,
                Key: image.name,
                ContentType: 'image/jpeg'
              }).then(resolve);
            } catch (error) {
              console.log('imageUrl error', error);
              reject(error);
            }
          };

          img.onerror = () => {
            reject(new Error('Image loading failed'));
          };
        });
      })
    );

    const generatedLayersPromise = savePageLayers(
      store,
      keyBase,
      scene,
      isNewScene
    );
    const [generatedLayers] = await Promise.all([
      generatedLayersPromise,
      uploadSmallImagesPromise
    ]);
    const result = {
      layer: generatedLayers
    };
    result[`imageLanguages`] = languages;
    [...sceneImages.filter((p) => !p.isPortrait), ...landscapeImage].map(
      (image) => {
        const imageLanguage = image.language;
        const pages = scenePages.filter((p) => {
          const { language } = extractPageId(p.id);
          return language === imageLanguage;
        });
        const hasPortrait =
          checkForMissingPolotnoPage({ pages, width: 0, height: 0 }) !==
          'mobile';
        result[
          `mainImage${imageLanguage === 'en' ? '' : `-${imageLanguage}`}`
        ] = `${assetBaseUrl}/${image.name}`;
        result[
          `thumbnail${imageLanguage === 'en' ? '' : `-${imageLanguage}`}`
        ] = `${assetBaseUrl}/${image.name}`;
        result[
          `preview${imageLanguage === 'en' ? '' : `-${imageLanguage}`}`
        ] = `${assetBaseUrl}/${image.name}`;

        const portraitUrl =
          imageLanguage === 'en'
            ? image.name.replace('.jpeg', '_portrait.jpeg')
            : image.name.replace(
                `-${imageLanguage}.jpeg`,
                `_portrait-${imageLanguage}.jpeg`
              );
        result[
          `portraitImage${imageLanguage === 'en' ? '' : `-${imageLanguage}`}`
        ] = `${assetBaseUrl}/${!hasPortrait ? image.name : portraitUrl}`;
        result[
          `portraitThumbnail${
            imageLanguage === 'en' ? '' : `-${imageLanguage}`
          }`
        ] = `${assetBaseUrl}/${!hasPortrait ? image.name : portraitUrl}`;
        result[
          `portraitPreview${imageLanguage === 'en' ? '' : `-${imageLanguage}`}`
        ] = `${assetBaseUrl}/${!hasPortrait ? image.name : portraitUrl}`;
      }
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const saveEditorImage = async ({
  key,
  initStore,
  scene,
  isNewScene
}: {
  key: string;
  initStore?: StoreType;
  scene?: ILibraryScene;
  isNewScene?: boolean;
}): Promise<PolotnoGeneratedImages> => {
  const store = initStore || getPolotnoStore();
  const generatedKey = generateV4UUID();

  const keyBase = `content/generated/${key}/${generatedKey}`;

  const hasPortrait =
    checkForMissingPolotnoPage(getPolotnoJson().obj) !== 'mobile';

  const { language } = extractPageId(store.pages[0]?.id);

  const landscapeImage: SceneGeneratedPage[] = [
    {
      name: `${keyBase}/${mapLanguageToImageName('scene', language)}.jpeg`
    },
    {
      name: `${keyBase}/${mapLanguageToImageName(
        'scene_preview',
        language
      )}.jpeg`,
      pixelRatio: 0.5
    },
    {
      name: `${keyBase}/${mapLanguageToImageName(
        'scene_thumbnail',
        language
      )}.jpeg`,
      pixelRatio: 0.2
    }
  ];

  const portraitImage: SceneGeneratedPage[] = [
    {
      name: `${keyBase}/${mapLanguageToImageName(
        'scene_portrait',
        language
      )}.jpeg`
    },
    {
      name: `${keyBase}/${mapLanguageToImageName(
        'scene_preview_portrait',
        language
      )}.jpeg`,
      pixelRatio: 0.5
    },
    {
      name: `${keyBase}/${mapLanguageToImageName(
        'scene_thumbnail_portrait',
        language
      )}.jpeg`,
      pixelRatio: 0.2
    }
  ];

  const allImages = hasPortrait
    ? [
        ...landscapeImage,
        ...portraitImage.map((p) => ({ ...p, isPortrait: true }))
      ]
    : landscapeImage;

  try {
    const generatedImages = await Promise.all(
      allImages.map((image) =>
        generatedImageFromServer([
          {
            json: removeUnusedFontsInPage({
              ...store.toJSON(),
              pages: [store.toJSON().pages[image.isPortrait ? '1' : '0']]
            }),
            exportOptions: {
              pixelRatio: image.pixelRatio,
              mimeType: 'image/jpeg'
            },
            uploadOptions: {
              bucket: editorImageBucket,
              Key: image.name,
              ContentType: 'image/jpeg'
            }
          }
        ])
      )
    );

    const generatedLayers = await savePageLayers(
      store,
      keyBase,
      scene,
      isNewScene
    );
    const [mainImageName, previewName, thumbnailName] = landscapeImage.map(
      (image) => image.name
    );

    const [portraitImageName, portraitPreviewName, portraitThumbnailName] =
      portraitImage.map((image) => image.name);

    const result = {
      layer: generatedLayers
    };
    result[`imageLanguage`] = language;
    result[
      `mainImage${language === 'en' ? '' : `-${language}`}`
    ] = `${assetBaseUrl}/${mainImageName}`;
    result[
      `thumbnail${language === 'en' ? '' : `-${language}`}`
    ] = `${assetBaseUrl}/${thumbnailName}`;
    result[
      `preview${language === 'en' ? '' : `-${language}`}`
    ] = `${assetBaseUrl}/${previewName}`;
    result[
      `portraitImage${language === 'en' ? '' : `-${language}`}`
    ] = `${assetBaseUrl}/${hasPortrait ? portraitImageName : mainImageName}`;
    result[
      `portraitThumbnail${language === 'en' ? '' : `-${language}`}`
    ] = `${assetBaseUrl}/${
      hasPortrait ? portraitThumbnailName : thumbnailName
    }`;
    result[
      `portraitPreview${language === 'en' ? '' : `-${language}`}`
    ] = `${assetBaseUrl}/${hasPortrait ? portraitPreviewName : previewName}`;
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const getUploadedFileConvertingStatus = async (
  uploadId
): Promise<IConvertedFiles[]> => {
  const client = await apigClient(prodBaseUrlV2);
  const path = `/libraries/v1/files/uploadedFiles`;
  const body = {
    uploadId
  };
  try {
    const result = await client.invokeApi({}, path, 'POST', {}, body);
    return result?.data;
  } catch (e) {
    console.log('error get file list', e);
    return [];
  }
};

export const getEditorShapeListFromS3 = async () => {
  const payload = {
    Bucket: editorImageBucket,
    Prefix: 'editor/shape/',
    Delimiter: '/'
  };

  const { CommonPrefixes } = await listObjectsFromS3(payload);

  const data = await Promise.all(
    CommonPrefixes.map(async (prefix) => {
      const { Contents } = await listObjectsFromS3({
        Bucket: editorImageBucket,
        Prefix: prefix.Prefix
      });
      const shape = Contents.map((content) => ({
        name: content.Key.split('/')[2],
        url: `${assetBaseUrl}/${content.Key}`
      }));
      return shape;
    })
  );

  return flatten(data);
};

export const transferSceneOwnership = async (
  scene: ILibraryScene,
  newOwnerId: string
) => {
  const url =
    'https://5lcdbu2xk6.execute-api.ap-southeast-1.amazonaws.com/prod';
  const client = await apigClient(url);
  const path = `/libraries/v1/scenes/attributes`;
  const body = {
    id: scene.id,
    tags: scene.tags,
    visibility: scene.content.visibility,
    visibilityScope: scene.content.visibilityScope,
    createdBy: newOwnerId
  };
  return await client.invokeApi({}, path, 'POST', {}, body);
};